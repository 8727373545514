// @flow
import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Location } from '@reach/router';
import intersection from 'lodash.intersection';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link as GatsbyLink } from 'gatsby';
import cx from 'classnames';
import {
  Container,
  Box,
  Typography,
  Divider,
  Chip,
  Link,
} from '@material-ui/core';
import Hero from '../components/Merchant/shared/Hero';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Bg from '../images/case_study.svg';
import BgSp from '../images/case_study_sp.svg';
import arrow from '../images/arrow-casestudy.svg';
import { ShareButtons } from '../components';
import { fetchCaseStudies } from '../redux/case-study';
import Cases from '../components/Merchant/shared/Cases';
import ImagePopup from '../components/Merchant/shared/ImagePopup';
import ApplySection from '../components/Merchant/ApplySection';
import { caseStudyStyles } from './styles';
// import {
//   RecommendSec,
// } from '../components/Column/Article';

const Bold = ({ children }) => <span className="bold">{children}</span>;

const Text = ({ children }) => {
  if (children && (!children[0] || children[0] === '\n')) {
    return <br />;
  }

  if (get(children, '[0][0]') === '\n') {
    return (
      <>
        <br />
        <p>{children}</p>
      </>
    );
  }

  return <p>{children}</p>;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return children ? <Text>{children}</Text> : <br />;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <OutboundLink
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#E30E80' }}
        >
          {children}
        </OutboundLink>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const alt = get(node, 'data.target.fields.description["ja-JP"]', '');
      const src = get(node, 'data.target.fields.file["ja-JP"].url', '');
      const classes = caseStudyStyles({});

      if (src) {
        return (
          <Box className={classes.mainImg}>
            <br />
            <br />
            <img src={src} alt={alt} width="100%" loading="lazy" />
          </Box>
        );
      }

      return <br />;
    },
  },
};

const CaseStudyTemplate = ({
  _fetchCaseStudies,
  caseStudies,
  location,
  pageContext,
}) => {
  const {
    slug,
    title,
    problem,
    result,
    problems,
    results,
    heroImage,
    heroImageDesc,
    firstParagraphRichText,
    secondParagraphRichText,
    thirdParagraphRichText,
    firstSubtitle,
    secondSubtitle,
    thirdTitle,
    subImage1,
    subImage2,
    subImage3,
    subImage4,
    subImage1Desc,
    subImage2Desc,
    subImage3Desc,
    subImage4Desc,
    firstIntervieweeName,
    firstIntervieweeTitle,
    secondIntervieweeName,
    secondIntervieweeTitle,
    //merchantInfoTitle,
    companyName,
    merchantKeywords,
    //companyDescription,
  } = pageContext;

  const classes = caseStudyStyles({});

  useEffect(() => {
    _fetchCaseStudies();
  }, []);

  const cs = caseStudies.find((c) => c.slug === slug);
  const csRecommended = caseStudies
    .filter((c) => cs && intersection(c.catSlugs, cs.catSlugs).length)
    .filter((c) => c.slug !== slug);

  const [imgPopupState, setImgPopupState] = useState({
    isOpen: false,
    url: null,
  });

  const handleImgClick = (event) => {
    setImgPopupState({
      isOpen: true,
      url: event.target.src,
    });
  };

  const handleClosePopup = () => {
    setImgPopupState({
      isOpen: false,
      url: null,
    });
  };

  return (
    <>
      <Layout isMerchant>
        <Location>
          {() => (
            <SEO
              title={`導入事例 | ${title}`}
              pathname={location.pathname}
              audience="merchant"
            />
          )}
        </Location>
        <Hero bg={Bg} bgSp={BgSp} classNames={classes.hero} />
        <Container maxWidth="md" className={classes.container}>
          <Box className={classes.wrapper} component="section">
            <Box className={classes.breadcrumb}>
              <Link
                component={GatsbyLink}
                underline="always"
                to="/merchant/column/"
              >
                お知らせ
              </Link>
              <span>&gt;</span>
              <Link component={GatsbyLink} underline="always" to="#">
                {/* {<CSParentCategory cs={cs} slug={slug} />} */}
                {get(pageContext, 'tags[0].title', '導入事例')}
              </Link>
            </Box>
            <article>
              {title && (
                <Typography
                  className={classes.title}
                  variant="h3"
                  component="h2"
                >
                  {title.replace(/\|/g, '')}
                </Typography>
              )}
              <Box mt="24px" display="flex" justifyContent="flex-end">
                <ShareButtons url={location.href} />
              </Box>
              <Box className={classes.dividerWrapper}>
                <Divider />
              </Box>
              {problem && result && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.headline}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className={classes.sectionWrapper}
                  >
                    <Box className={classes.section}>
                      <Typography variant="p" component="p">
                        課題
                      </Typography>
                    </Box>
                    {problem && (
                      <Typography variant="body1" component="div">
                        {problem.replace(/\|/g, '')}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.arrowWrapper}
                  >
                    <img src={arrow} className={classes.arrow} alt="arrow" />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className={cx(
                      classes.sectionWrapper,
                      classes.sectionWrapperResult
                    )}
                  >
                    <Box className={cx(classes.section, classes.sectionResult)}>
                      <Typography variant="p" component="p">
                        成果
                      </Typography>
                    </Box>
                    {result && (
                      <Typography variant="body1" component="div">
                        {result.replace(/\|/g, '')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {problems && results && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.headline}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className={classes.sectionWrapper}
                  >
                    <Box className={classes.section}>
                      <Typography variant="p" component="p">
                        課題
                      </Typography>
                    </Box>
                    {problems && (
                      <ul className={cx(classes.listProblemResult)}>
                        {problems.map((p, index) => (
                          <li key={index}>
                            <Typography variant="body1" component="div">
                              {p.replace(/\|/g, '')}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.arrowWrapper}
                  >
                    <img src={arrow} className={classes.arrow} alt="arrow" />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className={cx(
                      classes.sectionWrapper,
                      classes.sectionWrapperResult
                    )}
                  >
                    <Box className={cx(classes.section, classes.sectionResult)}>
                      <Typography variant="p" component="p">
                        成果
                      </Typography>
                    </Box>
                    {results && (
                      <ul className={cx(classes.listProblemResult)}>
                        {results.map((r, index) => (
                          <li key={index}>
                            <Typography variant="body1" component="div">
                              {r.replace(/\|/g, '')}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </Box>
              )}
              <Box className={classes.mainImg}>
                <button onClick={handleImgClick}>
                  <img src={heroImage} alt={title} />
                  {heroImageDesc && (
                    <p className={classes.subImageDesc}>{heroImageDesc}</p>
                  )}
                </button>
              </Box>
              <article>
                {firstSubtitle && (
                  <Typography variant="subtitle2" component="h3">
                    {firstSubtitle}
                  </Typography>
                )}
                {firstParagraphRichText && (
                  <Box mb="50px" mt="40px">
                    <Typography variant="body1" component="div">
                      {documentToReactComponents(
                        firstParagraphRichText,
                        options
                      )}
                    </Typography>
                  </Box>
                )}

                {secondSubtitle && (
                  <Typography
                    component="h3"
                    variant="h2"
                    className={classes.secondSubtitle}
                  >
                    {secondSubtitle}
                  </Typography>
                )}
                {subImage1 ? (
                  <button onClick={handleImgClick}>
                    <img
                      className={classes.firstSubImage}
                      src={subImage1}
                      alt={title}
                      width="100%"
                      loading="lazy"
                    />
                    <p
                      className={cx(
                        classes.subImageDesc,
                        classes.firstSubImageDesc
                      )}
                    >
                      {subImage1Desc}
                    </p>
                  </button>
                ) : (
                  <br />
                )}
                {secondParagraphRichText && (
                  <Typography variant="body1" component="div">
                    {documentToReactComponents(
                      secondParagraphRichText,
                      options
                    )}
                  </Typography>
                )}
                {subImage2 && (
                  <div className={classes.photoGrid}>
                    <div className={classes.photoGridLeft}>
                      <button onClick={handleImgClick}>
                        <img
                          src={subImage2}
                          width="100%"
                          loading="lazy"
                          alt={title}
                        />
                        {subImage2Desc && (
                          <p className={classes.subImageDesc}>
                            {subImage2Desc}
                          </p>
                        )}
                      </button>
                    </div>
                    {subImage3 && (
                      <div className={classes.photoGridRight}>
                        <button onClick={handleImgClick}>
                          <img
                            src={subImage3}
                            width="100%"
                            loading="lazy"
                            alt={title}
                          />
                          {subImage3Desc && (
                            <p className={classes.subImageDesc}>
                              {subImage3Desc}
                            </p>
                          )}
                        </button>
                        {subImage4 && (
                          <button onClick={handleImgClick}>
                            <img
                              src={subImage4}
                              width="100%"
                              loading="lazy"
                              alt={title}
                            />
                            {subImage4Desc && (
                              <p className={classes.subImageDesc}>
                                {subImage4Desc}
                              </p>
                            )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {thirdTitle && (
                  <Box mt="50px">
                    <Typography variant="subtitle2" component="h3">
                      {thirdTitle}
                    </Typography>
                  </Box>
                )}
                {thirdParagraphRichText && (
                  <Box mt="50px">
                    <Typography variant="body1" component="div">
                      {documentToReactComponents(
                        thirdParagraphRichText,
                        options
                      )}
                    </Typography>
                  </Box>
                )}
              </article>
              <Box mt="50px" mb="40px">
                <Typography variant="subtitle2" component="h3">
                  {companyName}
                </Typography>
              </Box>
              <div>
                <div>
                  {firstIntervieweeTitle && (
                    <div>{firstIntervieweeTitle.replace(/\|/g, '')}</div>
                  )}
                  <h6>{firstIntervieweeName}</h6>
                </div>
                {(secondIntervieweeTitle || secondIntervieweeName) && (
                  <div>
                    <div>{secondIntervieweeTitle.replace(/\|/g, '')}</div>
                    {secondIntervieweeName && <h6>{secondIntervieweeName}</h6>}
                  </div>
                )}
              </div>
              <Box mt="40px" className={classes.keywords}>
                {merchantKeywords &&
                  merchantKeywords
                    .slice(0, 10)
                    .map((keyword) => (
                      <Chip key={keyword} color="default" label={keyword} />
                    ))}
              </Box>
              <Box mt="40px">
                <ShareButtons url={location.href} />
              </Box>
              {/* {csRecommended && !!csRecommended.length && (
                  <RecommendSec
                    recommendedArticles={csRecommended.slice(0, 3)}
                    isMerchant
                  />
                )} */}
            </article>
          </Box>
        </Container>
        {csRecommended.length ? (
          <Box textAlign="center" className={classes.recommendWrapper}>
            <Typography
              variant="h3"
              component="h5"
              className={classes.recommend}
            >
              こちらもオススメ
            </Typography>
            <div className={classes.recommendSlider}>
              {cs && (
                <Cases
                  categories={cs.catSlugs}
                  exceptId={slug}
                  settings={{
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                  }}
                />
              )}
            </div>
          </Box>
        ) : null}
        <ApplySection />
      </Layout>
      <ImagePopup
        handleClosePopup={handleClosePopup}
        imgPopupState={imgPopupState}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  caseStudies: state.caseStudy.data,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchCaseStudies: () => dispatch(fetchCaseStudies()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyTemplate);
